.hero {
    min-height: 80vh;
    position: relative;
}

.hero > video {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero__overlay {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    position: relative;
}

.hero__overlayContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    border-radius: 2rem;
}

.hero__description .highlight {
    position: relative;
}

.hero__description .highlight::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0;
    top: 100%;
    background-color: var(--indigo-500);
    border-radius: 0.5rem;
}


@media (max-width: 760px) {
    .hero {
        min-height: 50vh;
    }

    .hero__overlay {
        min-height: 50vh;
    }

    .hero__title {
        font-size: 2rem !important;
        font-weight: 700 !important;
    }

    .hero__description {
        max-width: 80% !important;
        margin-top: 1rem !important;
        margin-bottom: 2rem !important;
        font-size: 1rem !important;
    }
}
