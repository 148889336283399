.upcomingInterviews {
    padding-bottom: 1rem;
}

.upcomingInterviews__gridItem {
    height: 180px;
    min-width: 180px;
}

.upcomingInterviews__slotCard {
    background-color: var(--grey-light) !important;
    height: 100%;
    display: grid;
    place-items: center;
    position: relative;
    cursor: pointer;
    transition: var(--transition) !important;
    transform: scale(1);
}

.upcomingInterviews__slotCard--requested {
    background: var(--indigo-100) !important;
}

.upcomingInterviews__slotCard--matched {
    color: white !important;
    background: var(--indigo-700) !important;
}

.upcomingInterviews__slotCard--matched * {
    color: white !important;
}

.upcomingInterviews__slotCard--available {
    overflow: hidden;
}

.upcomingInterviews__interviewType {
    max-width: 137px;
}

.upcomingInterviews__chip {
    position: absolute;
    top: -16px;
    z-index: 2;
}
.upcomingInterviews__bottomRow {
    position: relative;
    bottom: 15px;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    transition: var(--transition) !important;
}

.upcomingInterviews__bottomButton {
    z-index: 2;
    background: white;
    width: 50px;
    border-radius: 20px;
    padding: 4px;
    box-shadow: var(--shadow);
    cursor: pointer;
    display: grid;
    place-items: center;
}

.upcomingInterviews__joinButton {
    color: var(--indigo-700) !important;
    text-decoration: none !important;
    font-weight: 600;
}

.upcomingInterviews__bottomButton svg {
    color: var(--grey) !important;
    height: 25px;
}

.upcomingInterviews__slotCard:hover {
    transform: scale(1.04);
    box-shadow: var(--shadow-xl);
}

.upcomingInterviews__gridItem:hover
    > .upcomingInterviews__bottomRow
    > .upcomingInterviews__bottomButton {
    transform: scale(1.04) !important;
    box-shadow: var(--shadow-xl) !important;
}

.upcomingInterviews__chip.matched {
    background-color: var(--indigo-400);
}

.upcomingInterviews__chip.not-matched {
    background-color: var(--red);
}

.upcomingInterviews__modalCloseButton {
    position: absolute !important;
    right: 4px;
    top: 4px;
}

.upcomingInterviews__modalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white);
    min-width: 500px;
    border-radius: 4px;
}

.upcomingInterviews__paddingBox {
    padding: 40px 30px 30px;
}

.upcomingInterviews__modalDateBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.upcomingInterviews__modalInterviewType {
    max-width: 244px;
    margin: 0 auto !important;
}

.upcomingInterviews__modalJoinButton {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 0.375rem;
    margin: 0 5px;
    box-shadow: var(--shadow);
    transition: var(--transition);
    font-size: 1rem;
    text-decoration: none;
    background-color: var(--indigo-600);
    color: var(--grey-light);
    font-weight: 500;
}

.upcomingInterviews__modalJoinButton:hover {
    background-color: var(--indigo-700);
}

.upcomingInterviews__modalActionButtonsWrapper {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upcomingInterviews__cancelTimer {
    font-weight: 700;
}

.upcomingInterviews__cancelWrapper {
    display: flex;
}

.upcomingInterviews__cancelControls {
    margin-left: auto;
}

.upcomingInterviews__cancellationReason {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    flex-direction: column;
}

.upcomingInterviews__cancellationText {
    padding-bottom: 10px;
}

@media (max-width: 1200px) {
    .upcomingInterviews__gridItem {
        margin-bottom: 1.5rem !important;
    }
}

@media (max-width: 720px) {
    .upcomingInterviews__gridItem {
        margin-bottom: 2rem !important;
    }
    .upcomingInterviews__modalWrapper {
        min-width: fit-content;
        max-width: 90vw;
    }
    .upcomingInterviews__cancelWrapper {
        flex-direction: column;
    }
    .upcomingInterviews__cancelControls {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 6px;
    }
}
