.scrollToTop {
    position: fixed;
    bottom: 3vh;
    right: 1vw;
    z-index: 15;
    border-radius: 50%;
    padding: 2px 2.5px 0;
    border: none;
    opacity: 1;
    background-color: var(--white);
    box-shadow: var(--shadow-xl);
    transform: translateX(0) scale(1);
    transition: var(--transition);
    cursor: pointer;
    width: 50px;
}

.scrollToTop.hide {
    transform: translateX(100px);
}

.scrollToTop:hover {
    transform: scale(1.1);
}

@media (max-width: 1000px) {
    .scrollToTop {
        right: 3vw;
        bottom: 2vh;
    }
}
