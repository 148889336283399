.features {
    max-width: 1200px;
    width: 85%;
    margin: 3rem auto 8rem;
}

/* feature item styles ************************************************/
.featureItem {
    background-color: var(--grey-light);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 0 1.5rem 1.5rem;
    box-shadow: var(--shadow);
    transition: var(--transition);
    transform: scale(1);
}

.featureItem:hover {
    transform: scale(1.1);
}

.featureItem:hover .featureItem__icon {
    background-color: var(--indigo-700);
}

.featureItem__icon {
    background-color: var(--indigo-500);
    padding: 0.75rem;
    border-radius: 0.375rem;
    display: grid;
    place-items: center;
    position: relative;
    top: -10%;
    transition: var(--transition);
}

.featureItem__icon svg {
    width: 30px;
    height: 30px;
}

@media (max-width: 760px) {
    .features__heading {
        font-size: 2rem !important;
        line-height: 2.25rem !important;
        font-weight: 700 !important;
    }
}
