.footer {
    max-width: 1200px;
    width: 85%;
    margin: 5rem auto 0;
    background-color: vra(--white);
}

.footer__logoWrapper {
    display: block;
    width: 270px;
}

.footer__socialIconsWrapper {
    display: flex;
    margin-bottom: 3rem;
}

.footer__socialIconsWrapper > a {
    margin-right: 1.8rem;
}

.footer__socialIconsWrapper > a > svg {
    width: 25px;
    transition: var(--transition);
}

.footer__socialIconsWrapper > a > svg:hover {
    fill: var(--grey);
}

.footer__list {
    padding: 0;
    list-style: none;
}

.footer__list > li {
    margin-bottom: 1.2rem;
}

.footer__list > li > a {
    text-decoration: none;
    color: var(--grey);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    transition: var(--transition);
}

.footer__list > li > a:hover {
    color: var(--grey-dark);
}

.footer__copyrightWrapper {
    border-top: 2px solid var(--grey-light);
    margin-top: 1rem;
}
