.buttonComp {
    padding: 10px 15px !important;
    border-radius: 0.375rem !important;
    margin: 0 5px !important;
    box-shadow: var(--shadow) !important;
    transition: var(--transition);
}

.buttonComp > p {
    font-size: 1rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

/* button sizes ********************************************************************/
.buttonComp.sm {
    padding: 5px 10px !important;
}

.buttonComp.sm > p {
    font-size: 0.8rem;
}

.buttonComp.lg {
    padding: 15px 20px !important;
}

.buttonComp.lg > p {
    font-size: 1.2rem;
}

.buttonComp.xl {
    padding: 20px 25px !important;
}

.buttonComp.xl > p {
    font-size: 1.4rem;
}

/* primary button styles *************************************************************/
.buttonComp.primary {
    background-color: var(--indigo-600) !important;
}

.buttonComp.primary > p {
    color: var(--grey-light) !important;
}

.buttonComp.primary:hover {
    background-color: var(--indigo-700) !important;
}

/* secondary button styles ***************************************************************/
.buttonComp.secondary {
    background-color: var(--indigo-100) !important;
}

.buttonComp.secondary > p {
    color: var(--indigo-700) !important;
}

.buttonComp.secondary:hover {
    background-color: var(--indigo-200) !important;
}

/* danger button styles ***************************************************************/
.buttonComp.danger {
    background-color: var(--red-100) !important;
}

.buttonComp.danger > p {
    color: var(--white) !important;
}

.buttonComp.secondary:hover {
    background-color: var(--indigo-200) !important;
}

/* disabled button styles ***************************************************************/
.buttonComp.disabled {
    background-color: var(--grey) !important;
}
