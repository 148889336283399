.meetingGateway {
    max-height: 100vh;
    min-height: 100vh;
}

.meetingGateway__container {
    height: 80vh;
    display: grid;
    place-items: center;
}

.meetingGateway__bodyWrapper {
    max-width: 1200px;
    width: 85%;
    display: flex;
    align-items: center;
}

.meetingGateway__videoWrapper {
    flex: 0.6;
    height: 100%;
    border-radius: 10px;
    max-height: 400px;
    overflow: hidden;
    position: relative;
    background-color: var(--grey-dark);
}

.meetingGateway__videoWrapper video {
    width: 100%;
    height: 100%;
    max-height: 400px;
    min-height: 400px;
    object-fit: cover;
}

.meetingGateway__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
}

.meetingGateway__circleButton {
    background-color: var(--grey) !important;
    margin: 0 0.4rem !important;
}

.meetingGateway__circleButton--off {
    background-color: var(--red) !important;
}

.meetingGateway__circleButton--blurOn {
    background-color: var(--indigo-600) !important;
}

.meetingGateway__joinWrapper {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.meetingGateway__interviewType {
    max-width: 470px;
    line-height: 2.4rem !important;
}

.meetingGateway__username {
    color: var(--grey-dark) !important;
    font-weight: 500 !important;
}

.meetingGateway__timer {
    display: flex;
    align-items: center;
    color: var(--grey-dark) !important;
    font-weight: 500 !important;
}

.meetingGateway__timer .timer {
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    margin-left: 10px;
    color: var(--grey-dark) !important;
}

.videoWrapper__configureButton {
    margin: 0 0.4rem !important;
    border-radius: 50%;
    background-color: var(--indigo-600) !important;
}

.videoWrapper__configureButton svg {
    height: 30px !important;
    color: white !important;
}

@media (max-width: 968px) {
    .meetingGateway__bodyWrapper {
        display: flex;
        flex-direction: column !important;
    }
    .meetingGateway__joinWrapper {
        padding-top: 1rem !important ;
    }
}
