.error404 {
    display: grid;
    place-items: center;
    min-height: 100vh;
}

.error404__lottieWrapper {
    width: 60vw;
}

.error404__link {
    color: var(--indigo-400);
    font-size: 1.2rem;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 3px solid var(--indigo-300);
    transition: var(--transition);
}

.error404__link:hover {
    color: var(--indigo-600);
    border-color: var(--indigo-600);
}

@media (max-width: 760px) {
    .error404__lottieWrapper {
        width: 100vw;
    }
}
