.callToAction {
    background-color: var(--grey-light);
    margin: 3rem 0;
    padding: 3rem 0;
}

.callToAction__buttonWrapper {
    text-align: center;
}

@media (max-width: 900px) {
    .callToAction__heading {
        font-size: 2rem !important;
        line-height: 2.25rem !important;
        font-weight: 700 !important;
        margin-bottom: 1rem !important;
    }
}
