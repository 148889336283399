.promotionBanner {
    background-color: var(--indigo-500);
    position: relative;
    width: 100%;
    padding: 0.4rem 1rem;
    display: grid;
    place-items: center;
}

.promotionBanner.hidden {
    display: none;
}

.promotionBanner__content {
    display: flex;
    align-items: center;
}

.promotionBanner__content * {
    text-align: center;
    font-size: 1rem;
    color: var(--white);
    margin: 0;
}

.promotionBanner__gramophoneIcon {
    background-color: var(--indigo-700);
    padding: 4px;
    border-radius: 4px;
    display: grid;
    place-items: center;
    margin-right: 8px;
}

.promotionBanner__gramophoneIcon > svg {
    width: 24px;
    height: 24px;
}

.promotionBanner__closeButton {
    position: absolute !important;
    right: 2px;
    top: 2px;
    width: 30px;
    height: 30px;
    padding: 5px !important;
    border-radius: 5px !important;
}
