.sidebar__sidebar .MuiDrawer-paper {
    background-color: var(--indigo-700);
}

.sidebar__menuIcon {
    font-size: 2rem;
}

.sidebar .css-1u2mxbp {
    transition: var(--transition) !important;
}

.sidebar.closed .css-1u2mxbp {
    padding: 0;
    margin: 0 auto;
}

.sidebar .MuiListItem-root {
    padding-left: 1.4rem;
}

.sidebar__sidebar .MuiTypography-root {
    color: var(--white);
}

@media (max-width: 850px) {
    .sidebar__sidebar {
        display: none;
    }
}
