.testerNotes__button {
    margin-left: 14px !important;
}

.testerNotes {
    width: 40vw;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
}

.testerNotes__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0;
}

.testerNotes__formWrapper {
    flex: 1;
    overflow-y: auto;
    margin: 1rem 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.feedbackInput {
    flex: 1;
}

@media (max-width:768px){
    .testerNotes {
        min-width: 350px;
        max-width: 350px;
    }
}
