.mobileUpdate {
    display: grid;
    place-items: center;
    min-height: 100vh;
}

.mobileUpdate__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 350px;
    background-color: var(--white);
    padding: 20px 40px 50px;
    box-shadow: var(--shadow-xl);
    border-radius: 10px;
}

.mobileUpdate__logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.2rem 0;
}

.mobileUpdate__logoWrapper .logoText {
    display: block;
    width: 270px;
}

.mobileUpdate__inputContainer {
    margin-bottom: 1.8rem !important;
}

.mobileUpdate__input {
    width: 100% !important;
}

@media (max-width: 420px) {
    .mobileUpdate__wrapper {
        padding: 20px;
        min-width: 300px;
    }
}
