.instantInterviewTimer {
    flex: 1;
    border-radius: 10px;
    background-color: var(--grey-dark);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    padding: 1rem;
}

.instantInterviewTimer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instantInterviewTimer__timer {
    color: var(--white);
    margin-bottom: 1rem !important;
    text-align: center !important;
}

.instantInterviewTimer__timer span {
    color: var(--indigo-400);
    font-weight: 700;
    font-size: 1.1rem;
}
