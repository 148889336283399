.meetFooter {
    min-height: 10vh;
    max-height: 10vh;
    box-sizing: border-box;
    padding: 10px 1.5rem;
}

.meetFooter__actionsLeft {
    display: flex;
    align-items: center;
}

.meetFooter__questionButton {
    color: var(--indigo-400) !important;
}

.meetFooter__circleButton {
    background-color: var(--grey) !important;
    color: white !important;
    margin: 0 0.4rem !important;
}

.meetFooter__circleButton--end,
.meetFooter__circleButton--off {
    color: white;
    background-color: var(--red) !important;
}

.meetFooter__circleButton--sharing {
    background-color: var(--indigo-500) !important;
}

.meetFooter__circleButton--whiteboard svg {
    height: 25px;
}

.meetFooter__circleButton--disabled {
    cursor: not-allowed !important;
    background-color: var(--grey-dark) !important;
}

.meetFooter__menuButton {
    padding: 0.05rem !important;
    display: none !important;
}

@media (max-width: 1300px) {
    .meetFooter__actionsLeft .mobile-hide {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .meetFooter {
        min-height: 5vh;
        max-height: 20vh !important;
        padding: 10px 0.5rem;
    }

    .meetFooter__actionButton {
        display: flex;
        justify-content: center !important;
    }

    .meetFooter__circleButton {
        margin: 0 0.15rem !important;
    }

    .meetFooter__menuButton {
        display: flex !important;
    }

    .meetFooter__circleButton--mobile-hide {
        display: none !important;
    }

    .meetFooter__actionsLeft {
        display: flex;
        margin-bottom: 0.8rem !important;
        justify-content: center !important;
    }

    .meetFooter__actionsRight {
        display: none !important;
    }
}
