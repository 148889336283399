.codeEditor {
    height: 100%;
    position: relative;
}

.codeEditor__wrapper {
    height: calc(100% - 60px);
    position: relative;
}

.codeEditor__wrapper.open {
    max-height: calc(100% - 300px);
}

.codeEditor__wrapper.full-height {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
}

.ace__editor * {
    font-family: monospace !important;
    direction: ltr !important;
    text-align: left !important;
}

/* CodeEditorNavbar styles ***********************************/
.codeEditorNavbar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 12px;
    display: flex;
    align-items: center;
    background-color: var(--grey-dark);
    border-radius: 8px;
}

.codeEditorNavbar__button {
    background-color: var(--grey) !important;
    color: white !important;
    margin: 0 0.4rem !important;
}

.codeEditorNavbar__button--disabled {
    cursor: not-allowed !important;
    background-color: var(--indigo-900) !important;
}

.codeEditorNavbar__darkThemeIcon {
    color: var(--grey-dark) !important;
    transform: rotateZ(-35deg) !important;
}

/* CodeLanguage styles *******************************************/
.codeLanguage {
    min-width: 150px !important;
    margin-left: 0.8rem !important;
}

.codeLanguage .MuiInputLabel-root {
    color: var(--white) !important;
}

.codeLanguage .MuiOutlinedInput-root {
    color: var(--white) !important;
}

.codeLanguage .MuiOutlinedInput-notchedOutline {
    border-color: var(--white) !important;
}

.codeLanguage .MuiSvgIcon-root {
    color: var(--white) !important;
}

/* CodeExecutor  styles ******************************************/
.codeResult {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 5;
    background-color: var(--grey-dark);
    max-height: 60px;
    min-height: 60px;
    transition: var(--transition);
    padding: 1rem;
}

.codeResult.open {
    max-height: 300px;
    min-height: 300px;
}

.codeResult__toggleButton {
    position: absolute !important;
    top: -20px;
    right: 0;
    transform: rotateZ(180deg);
    transition: var(--transition);
}

.codeResult__toggleButton.open {
    transform: rotateZ(0deg);
}

.codeResult__headerWrapper {
    display: flex;
    align-items: center;
}

.codeResult__statusWrapper {
    display: flex;
    align-items: center;
    background-color: var(--grey-light);
    padding: 4px 8px;
    border-radius: 50px;
    min-height: 33px !important;
}

.codeResult__resultWrapper {
    background-color: var(--indigo-900);
    color: #fff;
    margin: 1rem 0.5rem;
    padding: 1rem;
    max-height: 220px;
    overflow: auto;
}
