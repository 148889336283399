.profileStat__rangeSelectorWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem;
}

.profileStat__sidebarAndGraphWrapper {
    display: flex;
}

.profileStat__graphWrapper {
    flex: 0.8;
    margin-left: 1rem;
    height: 100%;
}

.profileStat__tabPanel {
    width: 100%;
}

@media (max-width: 720px) {
    .profileStat__sidebarAndGraphWrapper {
        flex-direction: column;
    }

    .profileStat .MuiTabs-flexContainerVertical {
        align-items: center !important;
    }

    .profileStat__graphWrapper {
        margin-top: 1rem;
        margin-left: 0;
    }
}
