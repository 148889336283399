.checkList {
    flex: 0.3;
    display: flex;
    flex-direction: column;
}

/* CheckListItem styles  *****************************************************/
.checkListItem {
    display: flex;
}

.checkListItem__iconAndLineWrapper {
    display: flex;
    margin-bottom: 4px;
    align-items: center;
    flex-direction: column;
    min-height: 100px;
}

.checkListItem__icon {
    background-color: var(--red-100);
    padding: 0.6rem;
    border-radius: 0.375rem;
    display: grid;
    place-items: center;
    transition: var(--transition);
}

.checkListItem__icon > svg {
    width: 20px;
    height: 20px;
}

.checkListItem:hover .checkListItem__icon {
    background-color: var(--red);
}

.checkListItem__line {
    flex: 1;
    background-color: var(--red-100);
    border-radius: 0.375rem;
    width: 4px;
    height: 100%;
    margin-top: 4px;
}

.checkListItem__textWrapper {
    margin-left: 1.5rem;
    margin-top: 0.4rem;
}

.checkListItem.lastItem .checkListItem__line {
    display: none;
}

.checkListItem.lastItem .checkListItem__iconAndLineWrapper {
    min-height: fit-content;
}

.checkListItem.not-done .checkListItem__icon {
    cursor: pointer;
}

.checkListItem.not-done .checkListItem__icon:hover {
    transform: scale(1.1);
}

.checkListItem.done .checkListItem__icon {
    background-color: var(--indigo-500);
}

.checkListItem.done:hover .checkListItem__icon {
    background-color: var(--indigo-700);
}

.checkListItem.done .checkListItem__line {
    background-color: var(--indigo-500);
}

@media (max-width: 1350px) {
    .checkList {
        margin-top: 2rem;
        width: 70%;
    }
}

@media (max-width: 700px) {
    .checkList {
        width: 90%;
    }
}
