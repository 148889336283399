.reportForm__button {
    color: var(--grey);
}

.buttonComp.buttonComp__variant--grey {
    background-color: var(--grey) !important;
}

.buttonComp.buttonComp__variant--grey:hover {
    background-color: var(--grey-dark) !important;
}
