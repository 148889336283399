.navbar {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    background-color: rgba(255, 255, 255, 0.1);
    position: sticky;
    top: 0;
    box-shadow: var(--shadow);
    z-index: 10;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    box-sizing: border-box;
}

.navbar__logoWrapper {
    transition: all 0.3s ease;
}

.navbar__logoWrapper .logoText {
    display: block;
    width: 270px;
}

.navbar__logoWrapper .logoIcon {
    display: none;
    width: 40px;
}

.navbar__logoWrapper.shiftLogo {
    margin-left: 60px;
}

.navbar__logoWrapper.shiftLogoMore {
    margin-left: 180px;
}

.navbar__itemsWrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
}

@media (max-width: 760px) {
    .navbar {
        padding: 15px;
    }

    .navbar__logoWrapper .logoText {
        display: none;
    }

    .navbar__logoWrapper .logoIcon {
        display: block;
    }

    .navbar .buttonComp.buttonComp__mobile--hidden {
        display: none;
    }
}

@media (max-width: 850px) {
    .navbar__logoWrapper.shiftLogo {
        margin-left: 0px;
    }

    .navbar__logoWrapper.shiftLogoMore {
        margin-left: 0px;
    }
}
