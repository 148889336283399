.chat__chatButton {
    color: var(--indigo-400) !important;
}

.chat {
    width: 28vw;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
}

.chat__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0;
}

.chat__messagesWrapper {
    flex: 1;
    overflow-y: auto;
    margin: 0 0 1rem 0;
    padding: 0 20px;
}

.chatMessage__bodyWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    max-width: 28vw;
}

.chatMessage__message {
    overflow-wrap: break-word !important;
    overflow: hidden;
    flex: 0.8;
}

.chatMessage__timestamp {
    flex: 0.2;
}

@media (max-width: 768px) {
    .chat {
        min-width: 100vw;
        max-width: 100vw;
    }
    .chatMessage__bodyWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        max-width: 90vw;
    }
}
