.noVideoCard {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: var(--grey-dark);
}
