.nice-dates-navigation,
.nice-dates-day {
    color: #111;
}

.nice-dates-popover {
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 2px;
    max-width: 480px;
    transition: none;
}

.nice-dates > input {
    width: 100%;
    height: 56px;
    border: 1px solid #bbb;
    color: var(--grey-dark);
    letter-spacing: 1px;
    font-size: 0.9rem;
    font-family: 'Poppins', sans-serif !important;
    padding-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
}

.nice-dates > input:hover {
    border: 1px solid var(--grey-dark);
}

.nice-dates > input:focus {
    border: 2px solid var(--indigo-500);
    outline: none;
}

.nice-dates-grid {
    height: 240px !important;
}

.nice-dates-grid_container > span {
    height: 40px !important;
}

.nice-dates-navigation_previous,
.nice-dates-navigation_next {
    padding-bottom: 45px !important;
}

.scheduler__modal .MuiPaper-root {
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    display: grid;
    place-items: center;
}

.scheduler__schedulerBox {
    background-color: var(--white);
    border-radius: 4px;
}

.scheduler__paddingBox {
    margin: 2rem 2rem 5rem;
}

.scheduler__actionButtons {
    float: right;
    margin-top: 15px;
}

.scheduler__interviewType {
    margin: 15px 0;
}

.scheduler__customNotification {
    color: var(--grey);
    font-size: 0.9rem;
}

.scheduler__customNotification a {
    color: var(--indigo-500);
    font-weight: 500;
}

@media (max-width: 720px) {
    .scheduler__schedulerBox {
        max-width: 90vw;
    }

    .scheduler__schedulerBox .mobile-heading {
        font-size: 1rem !important;
    }

    .nice-dates > input {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .nice-dates-popover {
        max-width: 380px;
    }
}

@media (max-width: 500px) {
    .nice-dates-popover {
        max-width: 300px;
    }
}

@media (max-width: 400px) {
    .nice-dates-popover {
        max-width: 260px;
    }
}
