.feedbackForm {
    max-width: 1200px;
    width: 85%;
    margin: 0 auto;
    padding: 3rem 0 8rem;
}

.feedbackForm__wrapper {
    margin: 3rem 0;
}

.feedbackForm__buttons {
    display: flex;
    justify-content: space-between;
}

/* FeedbackFormItem styles ****************************************************************/

.feedbackFormItem {
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid var(--grey-light);
}
