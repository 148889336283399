.userProfile {
    display: flex;
    justify-content: center;
    flex: 0.7;
}

.userProfile__avatarSection {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userProfile__detailsSection {
    flex: 0.7;
}

@media (max-width: 1350px) {
    .userProfile {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .userProfile {
        flex-direction: column;
        align-items: center;
    }

    .userProfile__detailsSection {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
