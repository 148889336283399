.pastInterviews__modal {
    position: absolute;
    width: 90vw;
    top: 50%;
    left: 50%;
    height: 90vh;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 20px;
}

.pastInterviews__modalContentWrapper {
    display: flex;
}

.pastInterviews__left {
    flex-basis: 75%;
}

.pastInterviews__right {
    flex-basis: 25%;
    margin-top: 5vh;
}

.pastInterviews__feedbackTab {
    width: 100%;
}

.pastInterviews__commentWrapper {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 20px;
}

.pastInterviews__commentWrapper:nth-child(2) {
    background-color: var(--indigo-50);
}

.pastInterviews__commentField {
    background-color: white;
}

.pastInterviews__rating {
    margin-top: 10px;
    display: flex;
}

.pastInterviews__chatsWrapper {
    max-width: 400px;
    margin-left: 20px;
}

.pastInterviews__codeTab {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-right: 1rem;
}

.pastInterviews__codeTab .ace__editor {
    width: 100% !important;
}

.pastInterviews__questionTab {
    height: 100%;
    width: 100%;
    overflow: overlay;
    overflow-x: scroll;
}

.pastInterviews__markdownWrapper {
    padding: 20px 30px 20px;
}

.pastInterviews__markdownWrapper code {
    background-color: var(--grey-light);
    padding: 8px;
    margin: 8px 0;
    display: block;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    overflow-x: scroll !important;
    white-space: pre-wrap !important;
}

.pastInterviews__markdownWrapper * {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.pastInterviews__overall {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--indigo-600);
    padding: 10px;
    color: #fff;
    margin-bottom: 30px;
}

.pastInterviews__overall > div {
    margin: 5px 0;
}

.pastInterviews__overallRating {
    background-color: white;
    padding-top: 5px;
}

.pastInterviews__modalButtonWrapper {
    width: 100%;
    margin: 20px 0;
    text-align: center;
}

.pastInterviews__modalButtonWrapper .buttonComp {
    width: 80%;
}

.pastInterviews__closeButton {
    font-size: 30px;
    position: absolute !important;
    top: 0;
    right: 0;
    cursor: pointer;
}

@media (max-width: 720px) {
    .pastInterviews__tableWrapper {
        width: 100%;
    }

    .pastInterviews__modalContentWrapper {
        flex-direction: column-reverse;
    }
}

.ace__editor * {
    font-family: monospace !important;
    direction: ltr !important;
    text-align: left !important;
}
