.meeting {
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dark-bg) !important;
}

.meeting__container {
    display: flex;
    max-height: 80vh;
    min-height: 80vh;
    width: 100%;
    padding: 1.5rem 1.5rem 0 1.5rem;
    box-sizing: border-box;
}

.meeting__leftWrapper {
    flex: 0.75;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 0.75rem;
    position: relative;
}

.meeting__rightWrapper {
    flex: 0.25;
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
}

.meeting__screenVideo {
    width: 100%;
    height: 100%;
    object-fit: fill;
    background-color: var(--grey-dark);
    visibility: hidden;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.meeting__screenVideo--show {
    visibility: visible;
    z-index: 5;
}

.meeting__videoWrapper {
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--grey-dark);
    position: relative;
}

.meeting__videoWrapper:nth-child(1) {
    margin-bottom: 0.75rem;
}

.meeting__videoWrapper:nth-child(2) {
    margin-top: 0.75rem;
}

.meeting__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.meeting__micStatusIcon {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 4;
}

.meeting__roleTag {
    position: absolute;
    bottom: 8px;
    right: 6px;
    z-index: 4;
    background-color: var(--indigo-700) !important;
}

@media (max-width: 768px) {
    .meeting {
        height: 100vh !important;
        overflow: hidden !important;
    }

    .meeting__container {
        display: flex;
        max-height: 70vh;
        min-height: 70vh;
        width: 100%;
        padding: 1rem 1rem 0 1rem;
        box-sizing: border-box;
    }
    .meeting__leftWrapper {
        flex: 0 !important;
    }
    .meeting__rightWrapper {
        flex: 1 !important;
    }
}
