.notificationToast {
    box-shadow: var(--shadow-xl);
}

/* general notifications styling *********************************/
.notification {
    display: flex;
    align-items: flex-start;
}

.notification > svg {
    width: 25px;
    min-width: 25px !important;
    margin-right: 10px;
}

.notification > p {
    font-family: 'Poppins', sans-serif;
    color: var(--grey);
    font-size: 0.9rem;
}

/* chat notification styling  ***********************************/
.chatNotification__message {
    max-width: 265px;
    max-height: 150px;
    overflow: hidden;
    overflow-wrap: break-word !important;
    text-overflow: ellipsis !important;
    margin-top: 0.4rem !important;
}
