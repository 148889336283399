.whyUs {
    background-color: var(--grey-light);
    margin: 3rem 0;
    padding: 4rem 0 1rem;
    position: relative;
    overflow: hidden;
}

.whyUs > svg {
    position: absolute;
    top: 0;
    right: -5%;
    height: 100%;
    opacity: 0.2;
}

.whyUs__colsWrapper {
    max-width: 1200px;
    max-width: 85%;
    margin: 3rem auto;
}

.whyUs__videosWrapper {
    display: flex;
    flex-direction: column !important;
    align-items: center;
}

.whyUs__videosWrapper > video {
    border-radius: 1rem;
    max-width: 100%;
    height: auto;
    box-shadow: var(--shadow-xl);
}

.whyUs__videosWrapper > video:nth-child(1) {
    z-index: 4;
}

/* WhyUsItem styles **************************************************/
.whyUsItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
}

.whyUsItem__icon {
    background-color: var(--indigo-500);
    padding: 0.75rem;
    border-radius: 0.375rem;
    display: grid;
    place-items: center;
    margin-right: 1.2rem;
    transition: var(--transition);
}

.whyUsItem__icon svg {
    width: 30px;
    height: 30px;
}

.whyUsItem:hover .whyUsItem__icon {
    background-color: var(--indigo-700);
}

@media (max-width: 760px) {
    .whyUs {
        padding: 0.5rem 0 0.2rem;
    }

    .whyUs > svg {
        display: none;
    }

    .whyUs__colsWrapper {
        max-width: 80%;
    }

    .whyUs__heading {
        font-size: 2rem !important;
        line-height: 2.25rem !important;
        font-weight: 700 !important;
    }

    .whyUs__videosWrapper {
        display: none;
    }
}
