.questions__sidebar .MuiDrawer-paper {
    background-color: var(--white);
}

.questions__closeIcon {
    font-size: 2rem !important;
}

.questions__contentWrapper {
    padding: 0 1rem;
    height: 100%;
    width: 100%;
    overflow: overlay;
    max-width: 400px !important;
    overflow-x: scroll;
}

.questions__markdownWrapper code {
    background-color: var(--grey-light);
    padding: 8px;
    margin: 8px 0;
    display: block;
    max-width: 400px !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    overflow-x: scroll !important;
    white-space: pre-wrap !important;
}

.questions__markdownWrapper * {
    max-width: 400px;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}
