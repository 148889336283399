.instant {
    min-height: 100vh;
}

.instant__bodyWrapper {
    display: flex;
    align-items: center;
    max-width: 85vw;
    margin: 1.4rem auto;
    min-height: 65vh;
}

.instant__type {
    flex: 1;
}

.instant__timer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instant__status {
    flex: 1;
}

.instant__timer > div {
    margin-bottom: 30px;
}

.instant__timer .instant__timerNumber {
    font-size: 4rem;
    color: var(--indigo-600);
}

.instant__buttonIcon {
    margin-left: 10px;
}

/* Instant Type ***********************************************************/

.interviewType__wrapper {
    display: flex;
    flex-direction: column;
}

.interviewType__card {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--grey-light);
    margin: 8px 0;
    padding: 6px 16px;
    border-radius: 8px;
}

.interviewType__card svg {
    height: 80px;
    width: 80px;
    margin-right: 12px;
}

.interviewType__card.active {
    background-color: var(--indigo-100);
}

.interviewType__card.active h1 {
    color: var(--indigo-800);
}

/* Interview Status Card **************************************************/

.instantStatusCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--grey-light);
    box-shadow: var(--shadow);
    border-radius: 8px;
    padding: 20px;
    min-height: 380px;
}

.instantStatusCard > div {
    max-width: 280px !important;
}

.instantStatusCard__message {
    text-align: center;
    font-size: 1.35rem !important;
    line-height: 2rem !important;
    letter-spacing: -0.025rem !important;
    font-weight: 500 !important;
    margin-top: 0.5rem !important;
    max-width: 300px;
}

.instantStatusCard__instructionButton {
    color: var(--indigo-600);
    font-size: 1.2rem;
    cursor: pointer;
    border-bottom: 3px solid var(--indigo-400);
    font-weight: 500;
}

.instantStatusCard__instructionButton:hover {
    color: var(--indigo-700);
    border-color: var(--indigo-700);
}

/* Instant Instructions ************************************************/
.instantInstructions__stepsWrapper {
    flex-direction: column !important;
    padding: 1rem;
}

.instantInstructions__stepsWrapper .flowItem {
    flex-direction: row;
}

.instantInstructions__stepsWrapper .flowItem__iconAndLineWrapper {
    flex-direction: column;
    min-height: 150px;
}

.instantInstructions__stepsWrapper .flowItem__textWrapper {
    margin-left: 1.5rem;
}

.instantInstructions__stepsWrapper .flowItem__line {
    width: 4px;
    height: 100%;
    margin: 10px 0;
}

/* Engineering Facts ****************************************************/

.engineeringFacts {
    width: 100%;
    display: grid;
    place-items: center;
}

.engineeringFacts__text {
    margin-top: 1rem !important;
    padding: 0 1rem !important;
    text-align: center;
    width: 50%;
    max-width: 500px;
    min-width: 320px;
}

/* ******************************************************************** */
@media (max-width: 1200px) {
    .interviewType__wrapper {
        margin-bottom: 2rem;
    }
}

@media (max-width: 1100px) {
    .instant__bodyWrapper {
        flex-direction: column;
    }

    .instant__status {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
