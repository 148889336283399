#meetingGatewayCanvasID {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: var(--grey-dark);
}

#meetingCanvasID {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: var(--grey-dark);
}

#meetingGatewayCanvasID.hidden,
#meetingCanvasID.hidden {
    z-index: -10;
}
