:root {
    --indigo-900: #01375a;
    --indigo-800: #005288;
    --indigo-700: #00609f;
    --indigo-600: #0071bc;
    --indigo-500: #0088e2;
    --indigo-400: #0294f4;
    --indigo-300: #54bbff;
    --indigo-200: #7acaff;
    --indigo-100: #bde5ff;
    --indigo-50: #f6fcff;
    --grey-light: #f9fafb;
    --grey-medium: #9ca3af;
    --grey: #6b7280;
    --grey-dark: #111827;
    --red: #d1003a;
    --red-100: #e64975;
    --green: #64cf0a;
    --blue: #005ac2;
    --black: #000000;
    --white: #ffffff;
    --dark-bg: #202124;

    --shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow-xl: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04), 0 -10px 15px rgba(0, 0, 0, 0.04);
    --transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
