.cookiesBanner {
    position: fixed;
    bottom: 10px;
    z-index: 5;
    width: 100%;
}

.cookiesBanner__container {
    margin: 0 auto;
    max-width: 85%;
    display: flex;
    align-items: center;
    background-color: var(--indigo-500);
    padding: 0.4rem 1rem;
    border-radius: 0.5rem;
    box-shadow: var(--shadow-xl);
}

.cookiesBanner__icon {
    width: 30px;
    height: 30px;
    background-color: var(--indigo-700);
    padding: 5px;
    border-radius: 5px;
    margin-right: 1rem;
}

.cookiesBanner__crossIcon svg {
    width: 20px;
}

@media (max-width: 1000px) {
    .cookiesBanner {
        display: none;
    }
}
