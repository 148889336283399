.scheduleanother__modal.MuiPaper-root {
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    display: grid;
    place-items: center;
}

.scheduleanother__schedulerBox {
    background-color: var(--white);
    border-radius: 4px;
}

.scheduleanother__paddingBox {
    margin: 2rem 2rem 2rem 2rem;
}

.scheduleanother__actionButtons {
    width: 100%;
    display: flex;
    justify-content: center;
}
@media (max-width: 720px) {
    .scheduleanother__schedulerBox {
        max-width: 90vw;
    }

    .scheduleanother__schedulerBox .mobile-heading {
        font-size: 1rem !important;
    }

    .nice-dates > input {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .nice-dates-popover {
        max-width: 380px;
    }
}

@media (max-width: 500px) {
    .nice-dates-popover {
        max-width: 300px;
    }
}

@media (max-width: 400px) {
    .nice-dates-popover {
        max-width: 260px;
    }
}
