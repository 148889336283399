.profile {
    min-height: 100vh;
}

.profile__wrapper {
    max-width: 85%;
    margin: -1rem auto;
}

.profile__firstCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1350px) {
    .profile__firstCard {
        flex-direction: column;
    }
}

@media (max-width: 1000px) {
    .MuiBox-root.css-zxdg2z {
        max-width: 100%;
    }

    .profile__wrapper {
        max-width: 100%;
    }
}

@media (max-width: 720px) {
    .mobile-heading {
        font-size: 1.4rem !important;
    }

    .mobile-subheading {
        font-size: 1rem !important;
    }
}
