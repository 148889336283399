.otpVerify {
    display: grid;
    place-items: center;
    min-height: 100vh;
}

.otpVerify__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    background-color: var(--white);
    padding: 30px 40px 50px;
    box-shadow: var(--shadow-xl);
    border-radius: 10px;
}

.otpVerify__logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.2rem 0;
}

.otpVerify__logoWrapper .logoText {
    display: block;
    width: 270px;
}

.otpVerify__phone {
    color: var(--indigo-700);
    font-weight: 600;
}

.otpVerify__editButton {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    color: var(--indigo-700);
    font-weight: 500;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: var(--transition);
    padding: 0;
}

.otpVerify__editButton:hover {
    border-color: var(--indigo-700);
}

.otpVerify__resendWrapper {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin: 0.5rem 0 1rem;
}

.otpVerify__resendButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--indigo-600);
    font-weight: 600;
    transition: var(--transition);
}

.otpVerify__resendWrapper .remainingCount {
    font-size: 0.8rem;
}

.otpVerify__resendButton:hover {
    color: var(--indigo-800);
}

.otpVerify__timer {
    color: var(--indigo-700);
    font-weight: 600;
}

@media (max-width: 420px) {
    .otpVerify__wrapper {
        padding: 20px;
        min-width: 300px;
        max-width: 320px;
    }
}
