.profileEdit {
    padding: 0.5rem;
}

.profileEdit__button {
    width: fit-content;
    margin: 0 !important;
}

.profileEdit__phoneWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.8rem 0 0.5rem;
    border: 1px solid #ccc;
    padding: 6px 15px;
    border-radius: 6px;
}
