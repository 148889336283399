.flow {
    margin: 3rem 0;
    overflow: hidden;
}

.flow__wrapper {
    max-width: 1200px;
    width: 85%;
    margin: 3rem auto;
}

.flow__stepsWrapper {
    margin-left: 5rem;
}

/* FlowItem styles  *****************************************************/
.flowItem {
    display: flex;
    flex-direction: column;
}

.flowItem__iconAndLineWrapper {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
}

.flowItem__icon {
    background-color: var(--indigo-500);
    padding: 0.75rem;
    border-radius: 0.375rem;
    display: grid;
    place-items: center;
    transition: var(--transition);
}

.flowItem__icon > svg {
    width: 30px;
    height: 30px;
}

.flowItem:hover .flowItem__icon {
    background-color: var(--indigo-700);
}

.flowItem__line {
    flex: 1;
    height: 4px;
    background-color: var(--indigo-500);
    border-radius: 0.375rem;
    margin: 0 10px;
}

@media (max-width: 760px) {
    .flow__wrapper {
        width: 80%;
    }

    .flow__heading {
        font-size: 2rem !important;
        line-height: 2.25rem !important;
        font-weight: 700 !important;
    }
}

@media (max-width: 1200px) {
    .flow {
        margin-bottom: -6rem !important;
    }

    .flow__stepsWrapper {
        flex-direction: column !important;
        max-width: 500px;
        margin: 0 auto;
    }

    .flowItem {
        flex-direction: row;
    }

    .flowItem__iconAndLineWrapper {
        flex-direction: column;
        min-height: 150px;
    }

    .flowItem__textWrapper {
        margin-left: 1.5rem;
    }

    .flowItem__line {
        width: 4px;
        height: 100%;
        margin: 10px 0;
    }
}
