.timer {
    display: flex;
    align-items: center;
    color: var(--white);
    margin-left: 8px;
    transition: all 0.2s ease;
}

.timer__text {
    color: var(--white);
    letter-spacing: -0.025em;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    min-width: 80px !important;
}

.timer__text.timer {
    font-size: 1.1rem;
    letter-spacing: 2px;
}

.timer svg {
    height: 25px !important;
    min-width: 25px !important;
    margin-right: 8px;
}

.timer.shiftTimer {
    margin-left: 260px;
}

.timer.shiftTimer .timer__text.mobile-hide {
    display: none;
}

@media (max-width: 1400px) {
    .timer__text.mobile-hide {
        display: none;
    }
}
