.internetSpeedPopup {
    position: absolute;
    top: -90px;
    left: calc(50% - 175px);
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 100;
    padding: 0.6rem 1rem;
    border-radius: 4px;
    display: flex;
    max-width: 350px;
    transition: var(--transition);
}

.internetSpeedPopup--show {
    top: 10px;
}

.internetSpeedPopup__message {
    margin-left: 8px !important;
    line-height: 1.2rem !important;
    font-size: 0.9rem !important;
    color: var(--grey-medium);
}
