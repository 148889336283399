.meetNavbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 1.5rem;
    background-color: var(--grey-dark);
    position: sticky;
    top: 0;
    box-shadow: var(--shadow);
    z-index: 10;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    box-sizing: border-box;
    max-height: 10vh;
    min-height: 10vh;
}

.meetNavbar__logoWrapper {
    transition: all 0.2s ease;
}

.meetNavbar__logoWrapper.shiftLogo {
    margin-left: 400px;
}

.meetNavbar__logoWrapper .logoText {
    display: block;
    width: 270px;
}

.meetNavbar__logoWrapper .logoText .text {
    fill: var(--white);
}

.meetNavbar__logoWrapper--mobile-show {
    display: none;
}

.meetNavbar__logoWrapper .logoIcon {
    display: block;
    width: 40px;
}
.meetNavbar__rightWrapper {
    display: flex;
    align-items: center;
}

.meetNavbar__actions {
    display: none;
}

@media (max-width: 768px) {
    .meetNavbar__logoWrapper--mobile-hide {
        display: none !important;
    }
    .meetNavbar__logoWrapper--mobile-show {
        background-color: var(--white);
        border-radius: 60%;
        padding: 0.8rem 0.6rem;
        height: auto;
        display: block !important;
    }
    .meetNavbar__actions {
        display: flex;
        margin-right: -20px !important;
    }
}
